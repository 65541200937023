import { Operator } from '@kurtosys/ksys-app-template/dist/models/common';
import { IConfiguration } from '../models/app/IConfiguration';

export const CONFIGURATION: IConfiguration = {
	culture: {
		base: 'Default',
		default: 'en-GB',
	},
	core: {
		serviceUrl: 'https://api-rel.fundpress.io',
		skeletonLoaders: {
			variables: [
				{
					key: 'fillColor',
					value: '#dddddd',
				},
			],
			loaders: [
				{
					id: 'ksys-app-start',
					breakpoints: [
						{
							minWidthInPixels: 0,
							style: {
								height: '86px',
							},
							children: [
								{
									'fill': '{fillColor}',
									'x': '0px',
									'y': '11.90625px',
									'height': '28px',
									'width': '100%',
								},
								{
									'fill': '{fillColor}',
									'x': '0px',
									'y': '59.8125px',
									'height': '18px',
									'width': '100%',
								},
							],
						},
					],
				},
			],
		},
	},
	components: {
		app: {
			cardProps: {
				props: {},
				queries: {
					title: {
						queryOptionsType: 'property',
						entityType: 'FUND',
						code: 'fund_name',
					},
				},
			},
		},
		userLogin: {
			redirectUrl: {
				queryOptionsType: 'loggedInUser',
				options: {
					loggedInUser: {
						code: 'roles',
						key: 'role',
					},
				},
				transforms: [
					{
						transformOptionsType: 'switch',
						switchOptions: {
							cases: [
								{
									id: 'ClientAdmin',
									conditional: {
										conditions: [
											{
												operator: 'includes' as Operator,
												value: 'ClientAdmin',
											},
										],
									},
									response: '/client-admin',
								},
								{
									id: 'ApiLoaderUser',
									conditional: {
										conditions: [
											{
												operator: 'includes' as Operator,
												value: 'ApiLoaderUser',
											},
										],
									},
									response: '/api-loader-user',
								},
							],
							defaultResponse: '/',
						},
					},
				],
			},
		},
	},
};
